<template>
  <v-card elevation="2" color="ele" outlined shaped class="mb-5">
    <v-list>
      <v-list-item-group v-model="model">
        <template v-for="(val, key) in customer">
          <v-list-item :key="key">
            <v-list-item-icon>
              <v-icon color="fiftyfifty">{{ iconMap[key] }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="fiftyfifty--text">{{ $t("profile.list." + key) }}</v-list-item-title>
              <v-list-item-title class="primary--text">{{ val }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin, formMixin } from "@/helpers/mixins";

import { mapGetters } from "vuex";

export default {
  mixins: [formatMixin, listMixin, formMixin],

  data() {
    return {
      customer: {},
      iconMap: {
        firstname: "mdi-rename-box",
        name: "mdi-tournament",
        lang: "mdi-web",
        email: "mdi-email-outline",
        email2: "mdi-email-plus-outline",
        birthdate: "mdi-cake-variant",
        birthplace: "mdi-map-marker-outline",
        familystatus: "mdi-family-tree",
        firstcontact: "mdi-human-greeting-proximity",
      },
    };
  },

  props: {},

  computed: {
    ...mapGetters([]),
  },

  created() {
    this.getCustomerData();
  },

  methods: {
    getCustomerData: function () {
      axios
        .get(`/api/app/profile`)
        .then((customer) => {
          this.customer = customer.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.a {
  background-color: #818181;
}
</style>