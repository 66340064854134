<template>
  <DefaultLayout>
    <template v-slot:header>
      <h1>{{ $t("profile.pageTitle") }}</h1>
    </template>
    <v-container> <Profile /> </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import Profile  from "@/components/customers/profile.vue";
import { mapGetters } from "vuex";

export default {
  name: "home",
  mixins: [],
  components: {
    DefaultLayout,
    Profile
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters(["userName"]),
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
